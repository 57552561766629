.application-container {
  display: flex;
  flex-direction: column;

  justify-content: start;
  text-align: center;

  gap: 50px;
}
.fancyList {
  list-style-type: circle;
  padding: 0;
  width: 80%;
  text-align: right;
  font-size: large;
  font-weight: bold;
}

.fancyItem {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.2s, background-color 0.2s;
}
