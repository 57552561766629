.teachers-component-container {
  height: 800px;
  background: linear-gradient(
    90deg,
    #090d2c -0.98%,
    rgba(9, 13, 44, 0.98) 23.21%,
    rgba(9, 13, 44, 0.96) 50.18%,
    rgba(9, 13, 44, 0.94) 76.85%,
    rgba(9, 13, 44, 0.92) 99.98%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.teachers-component-title {
  color: white;
}

.teachers-component-inner-container {
  height: 500px; /* Adjust height as necessary */
  background-color: #fff;
  border-radius: 0.25em;
  width: 70%;
  display: flex;
  box-sizing: border-box;
  gap: 30px;
  overflow-y: hidden;
  overflow-x: scroll; /* Enables vertical scrolling */
  padding: 15px; /* Optional, for better spacing */
}

.courses-filter {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.courses-filter select {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 20rem;
  padding: 0.7rem 5rem 0.7rem 0.7rem;
  background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
      no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, #090d2c 3em, #fff 3em);
  color: #090d2c;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.teacher-component-card {
  height: 450px;
  display: flex;
  width: 290px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(
    163deg,
    #000 18.28%,
    #03213f 49.61%,
    #000 80.18%,
    #000 100%
  );
  border-radius: 20px;
  flex: 0 0 auto;
  transition: 0.2s ease-in-out;
}

.teacher-component-card:hover {
  transform: scale(1.02);
}

.teacher-component-card p {
  color: #fff;

  width: 260px;
  text-align: right;
  height: 82px;
  font-size: 13px;
}

.teacher-name-and-img {
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
}

.teacher-name-and-img img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
}

.teacher-btn {
  height: 60px;
  width: 150px;
  background-color: #fabd03;
  color: #090d2c;
  border: none;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .teachers-component-container {
    height: 800px;
    background: linear-gradient(
      90deg,
      #090d2c -0.98%,
      rgba(9, 13, 44, 0.98) 23.21%,
      rgba(9, 13, 44, 0.96) 50.18%,
      rgba(9, 13, 44, 0.94) 76.85%,
      rgba(9, 13, 44, 0.92) 99.98%
    );

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .teachers-component-title {
    color: white;
  }
  .courses-filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  .teachers-component-inner-container {
    height: 500px; /* Adjust height as necessary */
    background-color: #fff;
    border-radius: 0.25em;
    width: 20rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 20px;
    overflow-x: hidden;
    overflow-y: scroll; /* Enables vertical scrolling */
    padding: 10px; /* Optional, for better spacing */
  }

  .teacher-component-card {
    height: 450px;
    display: flex;
    width: 300px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    box-sizing: border-box;
    background: linear-gradient(
      163deg,
      #000 18.28%,
      #03213f 49.61%,
      #000 80.18%,
      #000 100%
    );
    border-radius: 20px;
    flex: 0 0 auto;
    transition: 0.2s ease-in-out;
  }

  .teacher-component-card:hover {
    transform: scale(1.02);
  }

  .teacher-name-and-img {
    display: flex;
    color: #fff;
    flex-direction: column;
    align-items: center;
  }

  .teacher-name-and-img img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .teacher-btn {
    height: 60px;
    width: 150px;
    background-color: #fabd03;
    color: #090d2c;
    border: none;
    border-radius: 5px;
  }
}
