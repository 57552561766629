.visas-container {
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #090d2c;
  gap: 10px;

  margin-bottom: 100px;
  box-sizing: border-box;
}
.visa-select {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 20rem;
  padding: 0.7rem 5rem 0.7rem 0.7rem;
  background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
      no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, #090d2c 3em, #fff 3em);
  color: #090d2c;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.visa-select::-ms-expand {
  display: none;
}

.visa-select:focus {
  outline: none;
}

.visa-select option {
  color: inherit;
  background-color: #fff;
}

.visas-inner-component {
  height: 550px; /* Adjust height as necessary */
  background-color: #090d2c;
  color: #fff;
  border-radius: 0.25em;
  overflow-y: scroll;
  width: 70%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  gap: 20px;

  padding: 10px; /* Optional, for better spacing */
}
.visa-ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
}

.visa-ul li a {
  color: #fabd03;
}
.visa-ul li {
  flex: 0 auto;
}
.visa-btn {
  padding: 5px 12px;
  background-color: #fabd03;
  color: #1f2123;
  border: 1px solid #fabd03;

  border-radius: 5px;
}
@media only screen and (max-width: 768px) {
  .visas-inner-component {
    height: 500px; /* Adjust height as necessary */
    background-color: #090d2c;
    color: #fff;
    border-radius: 0.25em;
    width: 20rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    gap: 20px;

    padding: 10px; /* Optional, for better spacing */
  }
  .visa-ul li {
    font-size: 14px;
  }
  .visa-btn {
    padding: 3px 8px;
    background-color: #fabd03;
    color: #1f2123;
    border: 1px solid #fabd03;
    font-size: 12px;
    border-radius: 5px;
  }
}
