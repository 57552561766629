.overview {
  color: #fff;

  background: linear-gradient(
    90deg,
    #090d2c -0.98%,
    rgba(9, 13, 44, 0.98) 23.21%,
    rgba(9, 13, 44, 0.96) 50.18%,
    rgba(9, 13, 44, 0.94) 76.85%,
    rgba(9, 13, 44, 0.92) 99.98%
  );

  height: 500px;

  display: flex;
  flex-direction: column;

  justify-content: center;
}

.two-slogans {
  font-size: 50px;
  font-weight: 500;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.start-button {
  width: 200px;
  height: 80px;
  border: none;
  border-radius: 80px;
  background-color: #2a9d92;
  color: #ffffff;
  transition: 0.2s ease-in-out;
  font-size: 30px;
  cursor: pointer;
}

.start-button:hover {
  transform: scale(1.2);
}

.start-button-container {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 992px) {
  .overview {
    color: #fff;

    height: calc(100vh - 66px);

    display: flex;
    flex-direction: column;

    justify-content: center;

    gap: 17vh;
  }

  .two-slogans {
    font-size: 30px;
    font-weight: 500;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 11vh;
  }

  .start-button {
    width: 200px;
    height: 80px;
    border: none;
    border-radius: 80px;
    background-color: #2a9d92;
    color: #ffffff;
    transition: 0.2s ease-in-out;
    font-size: 30px;
    cursor: pointer;
  }

  .start-button:hover {
    transform: scale(1.2);
  }

  .start-button-container {
    display: flex;
    justify-content: center;
  }
}

/*.overview-primary-btn {
  margin-left: 32px;
  padding: 16px 32px;
  background-color: #fabd03;
  color: #1f2123;
  width: 150px;
  border: 1px solid #fabd03;
  margin-bottom: 16px;
}

.slogan {
  font-size: 50px;
  font-weight: 600;
  color: #fff;
}

.overview-secondary-btn {
  padding: 16px;
  background-color: transparent;
  color: #eef0f0;
  border: 1px solid #eef0f0;
  width: 150px;
}

@media only screen and (min-width: 480px) {
  .overview-img {
    display: inline;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .overview-title {
    font-size: 56px;
  }
  .overview {
    padding: 128px 125px;
  }
  .overview-primary-btn {
    margin-bottom: 0px;
  }
}
*/
