.footer-container {
  width: 100%;
  height: 70px;
  background: linear-gradient(
    90deg,
    #090d2c -0.98%,
    rgba(9, 13, 44, 0.98) 23.21%,
    rgba(9, 13, 44, 0.96) 50.18%,
    rgba(9, 13, 44, 0.94) 76.85%,
    rgba(9, 13, 44, 0.92) 99.98%
  );
  display: flex;
  justify-content: center;
  color: #fff;
  gap: 40px;
}

.footer-container a {
  line-height: 70px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.footer-container span {
  line-height: 70px;
}

@media only screen and (max-width: 768px) {
  .footer-container {
    width: 100%;
    height: 70px;
    background: linear-gradient(
      90deg,
      #090d2c -0.98%,
      rgba(9, 13, 44, 0.98) 23.21%,
      rgba(9, 13, 44, 0.96) 50.18%,
      rgba(9, 13, 44, 0.94) 76.85%,
      rgba(9, 13, 44, 0.92) 99.98%
    );
    gap: 15px;
  }
}
