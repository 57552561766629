.nav-link {
  color: #fff;
  margin-right: 16px;
  font-size: 18px;
  font-weight: 600;
}

.menu-btn {
  color: #fff;
  margin-right: 16px;
  font-size: 18px;
  font-weight: 600;
  background: transparent;
  border: none;
  text-decoration: none;
}
.menu-btn a {
  margin: 0;
}

.menu-btn:hover {
  color: #fabd03;
}
.menu {
  background: linear-gradient(
    90deg,
    #090d2c -0.98%,
    rgba(9, 13, 44, 0.98) 23.21%,
    rgba(9, 13, 44, 0.96) 50.18%,
    rgba(9, 13, 44, 0.94) 76.85%,
    rgba(9, 13, 44, 0.92) 99.98%
  );
}

/* Add this CSS to your stylesheet or CSS file */
.custom-toggle-icon span {
  filter: invert(88%) sepia(1%) saturate(0%) hue-rotate(197deg) brightness(100%)
    contrast(101%);

  /* Replace #ff0000 with your desired color */
}

/* You may also need to change the color of the icon when it's active (open) */
.custom-toggle-icon[aria-expanded='true'] span {
  filter: invert(88%) sepia(1%) saturate(0%) hue-rotate(197deg) brightness(100%)
    contrast(101%);

  /* Replace #00ff00 with your desired color */
}

.logo {
  margin-right: 0px;
}
.login-btn {
  margin-left: 4px;
}

@media only screen and (max-width: 768px) {
  .menu-btn {
    text-align: start;
    margin-top: 20px;
  }
}
