:root {
  --scroller-size: 500vw;
}

.scroller-container {
  overflow: hidden;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;

  gap: 70px;
}
.scroller {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: var(--scroller-size);
  animation: icon-scroller 60s linear 0s normal infinite;
  will-change: transform;
}
.scroller-img {
  max-height: 300px;
  filter: brightness(0.3);
}

@keyframes icon-scroller {
  100% {
    transform: translateX(calc(var(--scroller-size) * -0.5));
  }
}

@media (max-width: 1200px) {
  :root {
    --scroller-size: 800vw;
  }
}
@media (max-width: 800px) {
  :root {
    --scroller-size: 1000vw;
  }
  .scroller-img {
    max-height: 200px;
  }
  .scroller-container {
    overflow: hidden;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 70px;
  }
  .scroller-container h1 {
    font-size: 30px;
    font-weight: 500;
  }
}

@media (max-width: 500px) {
  :root {
    --scroller-size: 1400vw;
  }
}
