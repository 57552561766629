.universities-component-container {
  height: 850px;
  background: linear-gradient(
    90deg,
    #090d2c -0.98%,
    rgba(9, 13, 44, 0.98) 23.21%,
    rgba(9, 13, 44, 0.96) 50.18%,
    rgba(9, 13, 44, 0.94) 76.85%,
    rgba(9, 13, 44, 0.92) 99.98%
  );

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  align-items: center;
}
.application-btn {
  padding: 7px 16px;
  background-color: #fabd03;
  color: #1f2123;
  border: 1px solid #fabd03;
  width: 20rem;
  height: 50px;

  border-radius: 5px;
}
.universities-component-title {
  color: #fff;
}
.universities-select {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 20rem;
  padding: 0.7rem 5rem 0.7rem 0.7rem;
  background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
      no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, #090d2c 3em, #fff 3em);
  color: #090d2c;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.universities-select::-ms-expand {
  display: none;
}

.universities-select:focus {
  outline: none;
}

.universities-select option {
  color: inherit;
  background-color: #fff;
}
.uni-search-input {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 70%;
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
  color: #090d2c;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.uni-search-button {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 30%;

  background-color: #fabd03;
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
  color: #090d2c;

  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.universities-component-list-container {
  height: 550px; /* Adjust height as necessary */
  background-color: #fff;
  border-radius: 0.25em;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll; /* Enables vertical scrolling */
  padding: 10px; /* Optional, for better spacing */
}

.uni-card {
  width: 100%;
  height: 180px; /* Fixed height */
  background-color: #fff;

  border-radius: 0.25em;
  overflow: hidden;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-around;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Additional styling as needed */
}

.uni-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.uni-name-and-img {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 100%;
  gap: 20px;
  justify-content: center;
}

.uni-filter-sliders {
  display: flex;
  gap: 20px;
  width: 70%;
}

.uni-name-and-img img {
  width: 100px;
}

.uni-rating {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
}

.uni-buttons {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.uni-btn {
  height: 60px;
  width: 150px;

  color: #fff;
  border: none;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .universities-component-container {
    height: 850px;
    background: linear-gradient(
      90deg,
      #090d2c -0.98%,
      rgba(9, 13, 44, 0.98) 23.21%,
      rgba(9, 13, 44, 0.96) 50.18%,
      rgba(9, 13, 44, 0.94) 76.85%,
      rgba(9, 13, 44, 0.92) 99.98%
    );

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .universities-component-list-container {
    height: 500px; /* Adjust height as necessary */
    background-color: #fff;
    border-radius: 0.25em;
    width: 20rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll; /* Enables vertical scrolling */
    padding: 10px; /* Optional, for better spacing */
  }
  .uni-card {
    text-align: center;
    width: 100%;
    height: 250px; /* Fixed height */
    background-color: #fff;
    padding: 10px;
    border-radius: 0.25em;
    overflow: hidden;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* Additional styling as needed */
  }
  .uni-filter-sliders {
    display: flex;
    flex-direction: column;
    width: 20rem;
    gap: 3px;
  }

  .application-btn {
    padding: 7px 16px;
    background-color: #fabd03;
    color: #1f2123;
    border: 1px solid #fabd03;
    width: 20rem;
    height: 50px;

    border-radius: 5px;
  }

  .uni-name-and-img {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .uni-name-and-img img {
    width: 70px;
  }
  .uni-name-and-img h4 {
    font-size: 15px;
    white-space: normal;
    text-align: center;
  }

  .uni-rating {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .uni-buttons {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }

  .uni-btn {
    height: 45px;
    width: 120px;

    color: #fff;
    border: none;
    border-radius: 5px;
  }
}
