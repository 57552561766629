.tutor-page-container {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 50px;
}

.tutor-page-inner-container {
  display: flex;
  justify-content: space-around;
}

.tutor-profile {
  width: 750px;
}
.tutor-video video {
  width: 640px;
  height: 360px;
  border-radius: 5px;
}

.tutor-video {
  border-radius: 5px;
  background-color: #fff;
  width: 640px;
  height: 360px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media only screen and (max-width: 768px) {
  .tutor-page-container {
    width: 100vw;
    height: 200vh;

    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 50px;
  }

  .tutor-page-inner-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    justify-content: space-around;
    gap: 50px;
  }

  .tutor-profile {
    width: 100%;
  }

  .tutor-video video {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .tutor-video {
    border-radius: 5px;
    background-color: #fff;
    width: 90vw; /* full width of the viewport */
    height: calc(90vw * 9 / 16); /* height based on 16:9 aspect ratio */
    max-height: 100vh; /* optional: to ensure it doesn't exceed the viewport height */
    overflow: hidden; /* to handle cases where height exceeds the viewport height */
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}
