.university-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.university-page-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  height: calc(100vh - 66px);
  padding-left: 240px;
  padding-right: 240px;
}
.uni-pros-and-cons {
  display: flex;
  justify-content: center;
  gap: 100px;
}
.uni-logo-name-location {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  color: #090d2c;
}
.application-btn-page {
  padding: 7px 16px;
  background-color: #fabd03;
  color: #1f2123;
  border: 1px solid #fabd03;
  width: 20rem;
  height: 50px;

  border-radius: 5px;
}

.uni-name-rating-location {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.uni-name-rating-location h1 {
  font-size: 25px;
}
.uni-name-rating-location h3 {
  font-size: 20px;
}

.uni-logo-name-location img {
  height: 70px;
}

.uni-pros ul {
  list-style: none;
  color: #090d2c;
  padding: 0;
  font-size: 15px;
}

.logo-and-button {
  display: flex;
  justify-content: space-between;
}

.uni-pros ul li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: green; /* Change the color */

  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  vertical-align: middle;
}
.uni-cons ul {
  list-style: none;
  color: #090d2c;
  padding: 0;
  font-size: 15px;
}

.uni-ratings {
  display: flex;

  justify-content: space-between;
  margin-top: 10px;
}

.uni-cons ul li::before {
  vertical-align: middle;
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: red; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
}
.uni-cons h3 {
  font-size: 19px;
}
.uni-pros h3 {
  font-size: 19px;
}
@media only screen and (max-width: 768px) {
  .university-page-container {
    width: 100vw;
    height: 250vh;
    display: flex;
    flex-direction: column;
  }
  .university-page-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 20px;
    margin-top: 20px;
    gap: 60px;
  }
  .uni-logo-name-location {
    display: flex;

    color: #090d2c;
  }
  .uni-page-name {
    font-size: 20px;
    margin: 0;
  }
  .uni-page-location {
    font-size: 18px;
  }
  .uni-logo-name-location img {
    height: 70px;
  }
  .uni-pros-and-cons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
  }

  .application-btn-page {
    padding: 7px 16px;
    background-color: #fabd03;
    color: #1f2123;
    border: 1px solid #fabd03;
    width: 100%;
    height: 50px;

    border-radius: 5px;
  }

  .logo-and-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .uni-pros ul {
    list-style: circle 2px;
    color: #090d2c;
    padding: 0;
  }

  .uni-pros ul li::before {
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: green; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
  }
  .uni-cons ul {
    list-style: none;
    color: #090d2c;
    padding: 0;
  }

  .uni-ratings {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 20px;
    text-align: center;
  }

  .uni-cons ul li::before {
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: red; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
  }
}
