.what-is-hegra-btn {
  padding: 7px 16px;
  background-color: #fabd03;
  color: #1f2123;
  border: 1px solid #fabd03;
  margin-bottom: 16px;
  border-radius: 5px;
}

.wih-container {
  height: 700px;
  padding: 50px;
  box-sizing: border-box;
}

@media only screen and (max-width: 992px) {
  .wih-container {
    height: 1600px;
    padding: 50px;
    box-sizing: border-box;
  }
}
