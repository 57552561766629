.tutor-info-container {
  border-radius: 20px;

  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 20px;
}
.tutor-img-and-name {
  display: flex;
  gap: 30px;
}

.tutor-img-and-name img {
  width: 120px;
  height: 120px;
  border-radius: 120px;
}

.tutor-name-and-rating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-size: 30px;
  font-weight: 500;
  color: #090d2c;
}

.tutor-description {
  color: #090d2c;
  font-size: 20px;
  font-weight: 400;
}
.level-select {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 20rem;
  padding: 0.7rem 5rem 0.7rem 0.7rem;
  background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
      no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, #090d2c 3em, #fff 3em);
  color: #090d2c;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.level-select::-ms-expand {
  display: none;
}

.level-select:focus {
  outline: none;
}

.level-select option {
  color: inherit;
  background-color: #fff;
}

.tutor-level-select {
  width: 150px;
  height: 60px;
  text-align: center;
  border: 2px solid #090d2c;
  border-radius: 20px;
}
.register-button {
  height: 60px;
  width: 250px;
  font-size: 17px;
  background-color: #fabd03;
  color: #090d2c;
  font-weight: 400;
  border: none;
  border-radius: 5px;
}
.register-buttons {
  display: flex;
  gap: 15px;
}

@media only screen and (max-width: 768px) {
  .register-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .register-button {
    height: 60px;
    width: 100%;
    font-size: 17px;
    background-color: #fabd03;
    color: #090d2c;
    font-weight: 400;
    border: none;
    border-radius: 5px;
  }
  .level-select {
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    /* Personalize */
    width: 20rem;
    padding: 0.7rem 5rem 0.7rem 0.7rem;
    background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
        no-repeat right 0.8em center / 1.4em,
      linear-gradient(to left, #090d2c 3em, #fff 3em);
    color: #090d2c;
    border-radius: 0.25em;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}
