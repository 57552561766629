.calulation-component-container {
  height: 900px;
  display: flex;
  justify-content: center;
  gap: 100px;
}
.calculator {
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.calculation-description {
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.calculation-description p {
  width: 100%;
  font-weight: 450;
}

@media only screen and (max-width: 768px) {
  .calulation-component-container {
    height: 900px;
    display: flex;
    justify-content: left;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
    padding: 20px;
    box-sizing: border-box;
  }

  .calculator {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .calculation-description {
    width: 100%;
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .calculation-description p {
    width: 100%;
  }
}
