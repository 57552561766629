.teacher-container {
  width: 80%;
  height: 600px;
  overflow-x: auto; /* Horizontal scroll for laptop view */
  overflow-y: hidden; /* Disable vertical scroll for laptop view */
  display: flex;
  flex-direction: row; /* Cards in a single line for laptop view */
  flex-wrap: nowrap; /* Prevents wrapping of cards */
}

.teacher-card {
  margin: 10px;
  flex: 0 0 auto; /* Prevents shrinking and maintains card size */
}

@media screen and (max-width: 768px) {
  .teacher-container {
    flex-direction: column; /* Cards in a single column for mobile view */
    overflow-x: hidden; /* Disable horizontal scroll for mobile view */
    overflow-y: auto; /* Vertical scroll for mobile view */
  }
}
