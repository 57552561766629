.perk:hover {
  cursor: pointer;
  transform: translateY(-7px);
}
.perk {
  height: 200px;
  background-color: #fff;
  color: #090d2c;
  font-size: 18px;
  font-weight: 400;
  transition: 0.2s ease-in-out;
  box-shadow: 0 0 35px -2px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 768px) {
  .perk p {
    font-size: 15px;
  }
}
